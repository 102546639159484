import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useRecoilState} from "recoil";
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import configParam from "../../config";
import jwtDecode from 'jwt-decode';
import Nifi from '../../assets/img/Nifi_resolution_1.gif'
import NifiIcon from '../../assets/img/NiFiIcon_2_20.jpeg'
import sglogo from '../../assets/img/SgLogo.png'
import { useMediaQuery } from '@mui/material';
import { userPlantState } from "../../recoil/atoms";
const StyledCard = styled(Card)({
  width: "100%",
  maxWidth: "400px",
  margin: "auto",
  borderRadius: theme => theme.spacing(2),
  boxShadow: theme => theme.shadows[3],
});

const StyledButton = styled(Button)({
  marginTop: theme => theme.spacing(2),
  backgroundColor: "#000057",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#000045",
  },
});


const Container = styled(Grid)({
  minHeight: "100vh",
  alignItems: "center",
  // background: "linear-gradient(to bottom, #f0f0f0, #d0d0d0)",
  overflow: "hidden",
});

const ContentContainer = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});
// const Container = styled(Grid)({
//   minHeight: "100vh",
//   alignItems: "center",
//   background: "linear-gradient(to bottom, #f0f0f0, #d0d0d0)",
//   overflow: "hidden",
// });

const Login = () => {
  const [user, setUser] = useState({});
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [,setPlantname]=useRecoilState(userPlantState);
  const handleSSoLogin = () => {
    const url = configParam.RUN_SSO_URL();
    window.location.replace(url);
  };
  // localStorage.clear();
  // console.log("Local storage after clearing:", localStorage);
  useEffect(() => {
    const userDetails = async () => {
      const url = window.location.href;
      const response = decodeURIComponent(url);
      const myArray = response.split("?");

      if (myArray.length > 1) {
        const queryParameters = new URLSearchParams(window.location.search)
        // const userDetails = queryParameters.get("UserData");
        // const currentUserDetails = JSON.parse(userDetails);
        // setUser(currentUserDetails);
        // const userlogoutdata = localStorage.getItem("user_id");
        // const userprocesslogoutdata = localStorage.getItem("user_process_id");
        const code = queryParameters.get("code");
 
        if (code) {
          try {
            // Call the API to check the user's authentication status
            const Apiurl = configParam.API_URL
            const response = await axios.get(`${Apiurl + "/login"}?token=${code}`);
     
            if (response.status === 200) {
              const userData = response.data;
              const access_token = userData.data.access_token.replace(/"/g, "");
              const refresh_token=userData.data.refresh_token.replace(/"/g, "");
              console.log("access_token",access_token)
              const token=jwtDecode(access_token);
              if (token) {
                await getUserDetails(token.userId, access_token,refresh_token);
                // localStorage.setItem("user", JSON.stringify(currentUserDetails));
                // let path = '/business';
      
                // if (currentUserDetails.role_id === '2') {
                //   path = `/plants/${currentUserDetails.location_id}`;
                // } else {
                //   path = `/analytics/${userlogoutdata || userprocesslogoutdata}`;
                // }
                // navigate(path);
              }
              else {
                localStorage.setItem("user", "");
                setError("Access denied");
              }
            }
          } catch (error) {
            // Handle any errors during the API call or authentication process
            console.error("Error during authentication:", error);
        
          }
        }
       
      }
    };
    const getUserDetails = async (id, access_token,refresh_token) => {
      access_token = access_token.replace(/"/g, "");
      refresh_token=refresh_token.replace(/"/g, "");
      const Apiurl = configParam.API_URL
      try {
        const response = await axios.get(Apiurl + `/check_user/${id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        });
        if (response.status === 200) {
          if (response && response.data) {
            let userData = response.data;
            console.log("userData",userData)
            userData["access_token"] = `${access_token}`;
            localStorage.setItem("user", JSON.stringify(userData));
            localStorage.setItem("accesstoken", access_token);
            localStorage.setItem("refreshtoken",refresh_token)
            let path = '/business';
            const userlogoutdata = userData.latest_plant_id;
            const response1 = await axios.get(Apiurl + `/business_cards/${userData.location_id}/${userData.id}`, {
              headers: {
                Authorization: `Bearer ${access_token}`,
               
              }
            });
    
            const data = response1.data;
            if (userlogoutdata) {
              path = `/analytics/${userlogoutdata}`;
              localStorage.setItem('user_id', userlogoutdata);
              const plant = data.find(plant => plant.id === userlogoutdata);
  
              // Check if a matching plant was found and retrieve its name
              if (plant) {
                const plantName = plant.plantName;
                localStorage.setItem('user_plant', plantName);
                setPlantname(plantName);
              }
            }
            else {
              if (userData.role_id === 2) {
                path = `/plants/${userData.location_id}`;
              }
            }

            navigate(path);
            window.location.reload();
          }
        } else {
       
        }
      } catch (error) {

        // Handle other types of errors
       

      }
    }
    userDetails();
  }, [navigate]);
  const isMobileView = useMediaQuery('(max-width:600px)');
  return (

    <Container container style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "85vh" }}>

      <Grid item xs={12} sm={6} style={{ padding: "15px", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <img src={Nifi} alt="nisLogo GIF" />
        <img src={NifiIcon} alt="Nifi Icon" />
        <StyledCard>
          <CardContent>
            <Typography variant="h5" gutterBottom style={{ textAlign: 'center' }}>
              SSO Login
            </Typography>
            <StyledButton variant="contained" fullWidth onClick={handleSSoLogin}>
              Sign-On
            </StyledButton>
            <Typography style={{ color: "#aaaaaa", fontSize: 16, fontWeight: 400 }} gutterBottom>
              Note:Login using Single Sign-On if you are Saint-Gobain User
            </Typography>
          </CardContent>
        </StyledCard>
        {isMobileView ? (
          <Typography style={{ color: "#aaaaaa", fontSize: 12, fontWeight: 400 }}>Copyright@23 Saint-Gobain India. All Rights Reserved</Typography>
        ) : (
          <React.Fragment>
            <img src={sglogo} alt="sg-logo" />
            <Typography variant="body1">Copyright@2024 INDEC4.0</Typography>
          </React.Fragment>
        )}
      </Grid>
    </Container>
  );
};

export default Login;
