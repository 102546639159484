import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import axios from 'axios';
import configParam from '../../config';
import { useRecoilValue } from 'recoil';
import { Link as RouterLink } from 'react-router-dom';
import { userPlantState, userLocationState } from '../../recoil/atoms';
import { useLocation } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';

function Business() {
  const [businesses, setBusinesses] = useState([]);
  const [plantDet, setPlantDet] = useState('');
  const [breadcrumb, setBreadcrumb] = useState('');
  const userPlant = useRecoilValue(userPlantState);
  const userLocation = useRecoilValue(userLocationState);
  const [access_token,setAccessToken]=useState(localStorage.getItem("accesstoken"))
  const [, setLocationDet] = useState({});
  const [userDet, setUserDet] = useState({});
  const location = useLocation();
  useEffect(() => {
    let data = localStorage.getItem('user');
    let User = JSON.parse(data);
    setUserDet(User);
  }, []);
  useEffect(() => {
    // Extract the last part of the current path as the breadcrumb
    const pathnames = location.pathname.split('/').filter((path) => path);
    if (pathnames.length > 1) {
      const currentBreadcrumb = pathnames[pathnames.length - 2];
      setBreadcrumb(currentBreadcrumb);
    }
    if (pathnames.length === 1) {
      const currentBreadcrumb = pathnames[pathnames.length - 1];
      setBreadcrumb(currentBreadcrumb);
    }
  }, [location]);

  useEffect(() => {
    // Function to check for accessToken changes
    const handleAccessTokenChange = () => {
      const storedAccessToken = localStorage.getItem("accesstoken");
      if (storedAccessToken !== access_token) {
        setAccessToken(storedAccessToken);
      }
    };
  
    // Listen for storage events (for cross-tab changes)
    window.addEventListener("storage", handleAccessTokenChange);
  
    // Check for changes every second (for same-tab changes)
    const interval = setInterval(handleAccessTokenChange, 1000);
  
    // Cleanup listeners and interval on component unmount
    return () => {
      window.removeEventListener("storage", handleAccessTokenChange);
      clearInterval(interval);
    };
  }, [access_token]); // Dependency on accessToken

  useEffect(() => {
    setLocationDet(userLocation || {});
  }, [userLocation]);
  useEffect(() => {
    setPlantDet(userPlant)
  }, [userPlant]);
  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        const ApiURL = configParam.API_URL;
        const response = await axios.get(ApiURL + '/business_cards', {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        });
        const data = response.data;
        setBusinesses(data);
      } catch (error) {
        console.error('Error fetching businesses:', error);
      }
    };

    fetchBusinesses();
  }, []);

  function formatBusinessName(name) {
    if (name) {
      return name.toUpperCase();
    }
    return ''; // Handle the case where name is undefined or null
  }
  const isMobileView = useMediaQuery('(max-width:600px)');
  return (
    <div>
      {isMobileView && (
        <div>


          {userDet.role_id === 2 ? (
            <div style={{ display: 'flex' }}>
              <RouterLink to={`/plants/${userDet.location_id}`}>
                <Typography style={{ color: '#1C1C1C' }}>Home</Typography>
              </RouterLink>
              <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>/</Typography>
            </div>
          ) : (
            <div style={{ display: 'flex' }}>
              <RouterLink to="/business">
                <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>Home</Typography>
              </RouterLink>
              <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>/</Typography>
              {userDet && (
                <RouterLink to={`/location/${userDet.business_id}`}>
                  <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>{formatBusinessName(userDet.location)}</Typography>
                </RouterLink>
              )}
              {userDet && (
                <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>/</Typography>
              )}
              {userDet && plantDet && (
                <RouterLink to={`/plants/${userDet.location_id}`}>
                  <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>{formatBusinessName(plantDet)}</Typography>
                </RouterLink>
              )}
              {userDet && (
                <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>/</Typography>
              )}
              <Typography style={{ color: '#1C1C1C', fontSize: '16px', fontWeight: 600 }}>{formatBusinessName(breadcrumb)}</Typography>
            </div>

          )
          }

        </div>
      )}
      {isMobileView ? (
        <hr style={{ margin: '10px 0', borderColor: '#0000001A' }} />
      ) : null}

      <Grid container className="business-container" spacing={2} style={{ marginTop: '20px' }}>
        {businesses.map((business) => (
          <Grid item lg={3} xs={12} sm={6} md={4} key={business.id}>
            <Link to={`/location/${business.id}`}>
              <Card
                sx={{
                  marginBottom: '16px',
                  backgroundColor: '#002060',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  cursor: 'pointer',
                  color: '#FFFFFF',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <CardContent>
                  <Typography style={{ fontWeight: 500, fontSize: '16px', color: '#FFFFFF', marginBottom: '5px' }}>
                    {formatBusinessName(business.business_name)}
                  </Typography>
                  <BusinessDetails businessId={business.id} />
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  );

}

function BusinessDetails({ businessId }) {
  const [businessData, setBusinessData] = useState({
    count: 'Loading...',
    averageNi: 'Loading...',
  });
  const [access_token,setAccessToken]=useState(localStorage.getItem("accesstoken"))

  useEffect(() => {
    // Function to check for accessToken changes
    const handleAccessTokenChange = () => {
      const storedAccessToken = localStorage.getItem("accesstoken");
      if (storedAccessToken !== access_token) {
        setAccessToken(storedAccessToken);
      }
    };
  
    // Listen for storage events (for cross-tab changes)
    window.addEventListener("storage", handleAccessTokenChange);
  
    // Check for changes every second (for same-tab changes)
    const interval = setInterval(handleAccessTokenChange, 1000);
  
    // Cleanup listeners and interval on component unmount
    return () => {
      window.removeEventListener("storage", handleAccessTokenChange);
      clearInterval(interval);
    };
  }, [access_token]); // Dependency on accessToken

  useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        const ApiURL = configParam.API_URL;
        let data1 = localStorage.getItem('user');
        let User = JSON.parse(data1);
        const response = await axios.get(ApiURL + `/business_cardsss/${User.id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        });
        const data = response.data;
        setBusinessData(data);
      } catch (error) {
        console.error(`Error fetching business data for ID ${businessId}:`, error);
        // Handle error gracefully
        setBusinessData({
          count: 0,
          averageNi: 0,
        });
      }
    };

    fetchBusinessData();
  }, []);

  return (
    <div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography style={{ fontWeight: 600, fontSize: "36px", color: '#FFFFFF' }}>
          {businessData.count}
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Typography style={{ fontWeight: 400, fontSize: "14px", color: '#FFFFFF' }}>
            {businessData.averageNi}
          </Typography>
          <Typography style={{ fontWeight: 400, fontSize: "14px", color: '#FFFFFF' }}>
            Average Nickel %:
          </Typography>

        </div>

      </div>
    </div>
  );
}

export default Business;






