import {React ,useState,useEffect,useRef}from 'react';
import { Button, TextField,FormControl,InputLabel,Select,Typography,MenuItem,Autocomplete} from '@mui/material';
import axios from "axios";
import configParam from '../../config';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { useRecoilState} from 'recoil';
import { UserSettings } from '../../recoil/atoms';
import { useNavigate } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';


function AddUser({ open, onClose,onSave,selectedRowUserData,selectedUserCardData,addedUsers,onHandleUser,onShowAlert,userDet,onHandleUserDateList,selectedUserYear}) {
   const[role,setRole]=useState(null);
   const[name,setName]=useState('');
   const[email,setEmail]=useState('');
   const[sgid,setSgid]=useState('');
   const [plant, setPlant] = useState(null);
   const [businessdata, setBusinessData] = useState([]);
   const [business, setBusiness] = useState(null);
   const [plantdata, setPlantData] = useState([]);
   const [roledata, setRoleData] = useState([]);
   const [area, setArea] = useState(null);
   const [areadata, setAreaData] = useState([]);
   const [isEmailValid, setIsEmailValid] = useState(true);
   const [sgidError, setSgidError] = useState('');
   const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isCursorInsideDialog, setIsCursorInsideDialog] = useState(false);
  const [selectedCreatedBy, setSelectedCreatedBy] = useState([]); // To store the selected options
const [selectAll, setSelectAll] = useState(false);
const [locationdata, setLocationData] = useState([]);
const [location, setLocation] = useState(null);
const[userDataSettings,setUserDataSettings]=useRecoilState(UserSettings)
const [userRole, setUserRole] = useState(null);
const [access_token,setAccessToken]=useState(localStorage.getItem("accesstoken"))
// const [selectedUserYear, setSelectedUserYear] = useState("");
const [currentaddedPlants, setCurrentAddedPlants] = useState([]);
const isMobileView = useMediaQuery('(max-width:600px)');
const navigate = useNavigate();
   const ApiURL =configParam.API_URL;
  
  const modalInputLabelStyle = {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#000',
  };


useEffect(() => {
  if (selectedRowUserData) {
    // If selectedRowData is present, it's an edit operation
    const selectedBusiness = businessdata.find((option) => option.business_name.toLowerCase()  === selectedRowUserData.business.toLowerCase());
    const selectedPlant = plantdata.filter(item => selectedRowUserData.plant.includes(item.plantName.toUpperCase()))
    .map(item => item.id)
    const selectedRole = roledata.find((option) => option.role === selectedRowUserData.role);
    const selectedLocation = locationdata.find((option) => option.location.toLowerCase() === selectedRowUserData.location.toLowerCase());
    // Make sure the state updates are correctly applied
    if (selectedBusiness) setBusiness(selectedBusiness.id);
    
    setSelectedCreatedBy(selectedPlant)
    const tabledataLength = plantdata.length;
    const plantLength = selectedRowUserData.plant.length;
    if(tabledataLength===plantLength){
      // setSelectAll(true)
    }
    
    
    if (selectedRole) setRole(selectedRole.id);
    if (selectedLocation) setLocation(selectedLocation.id);

    // Always set the other state variables
    setSgid(selectedRowUserData.sgid);
    setName(selectedRowUserData.name);
    setEmail(selectedRowUserData.email);
  } 


  else if (selectedUserCardData) {
   
    const selectedBusiness = businessdata.find((option) =>option.business_name.toLowerCase()  === selectedUserCardData.business_name.toLowerCase());
    // const selectedPlant = plantdata.filter(item => selectedUserCardData.plant_name.includes(item.plantName.toUpperCase()))
    // .map(item => item.id)
    // console.log("selectedPlant",selectedPlant)
    const selectedPlant = plantdata.filter(item => 
      selectedUserCardData.plant_name.includes(item.plantName)
  ).map(item => item.id);
 
  
    const selectedRole = roledata.find((option) => option.role === selectedUserCardData.role_name);
    const selectedLocation = locationdata.find((option) => option.location.toLowerCase() === selectedUserCardData.location.toLowerCase());
    // Make sure the state updates are correctly applied
    if (selectedBusiness) setBusiness(selectedBusiness.id);
    setSelectedCreatedBy(selectedPlant)
    const tabledataLength = plantdata.length;
    const plantLength = selectedUserCardData.plant_name.length;
    if(tabledataLength===plantLength){
      // setSelectAll(true)
    }
    if (selectedRole) setRole(selectedRole.id);
    if (selectedLocation) setLocation(selectedLocation.id);
    // Always set the other state variables
    setSgid(selectedUserCardData.sgid);
    setName(selectedUserCardData.name);
    setEmail(selectedUserCardData.email);
  }
  else {
    // If selectedRowData and selectedCardData are null, it's an add operation
  
    setBusiness(null);
    setPlant(null);
    setRole(null);
    setArea('');
    setSgid('');
    setName('');
    setEmail('');
    setIsEmailValid(true);
    setSgidError('');
    setNameError('');
    setEmailError('');
    setSelectedCreatedBy([])
    setLocation(null);
    getPlantDetails()
  }
}, [selectedRowUserData, selectedUserCardData, open]);

useEffect(() => {
  // Function to check for accessToken changes
  const handleAccessTokenChange = () => {
    const storedAccessToken = localStorage.getItem("accesstoken");
    if (storedAccessToken !== access_token) {
      setAccessToken(storedAccessToken);
    }
  };

  // Listen for storage events (for cross-tab changes)
  window.addEventListener("storage", handleAccessTokenChange);

  // Check for changes every second (for same-tab changes)
  const interval = setInterval(handleAccessTokenChange, 1000);

  // Cleanup listeners and interval on component unmount
  return () => {
    window.removeEventListener("storage", handleAccessTokenChange);
    clearInterval(interval);
  };
}, [access_token]); // Dependency on accessToken

useEffect(() => {
  let data = localStorage.getItem("user");
  let User = JSON.parse(data)

  if(User.role_id===1){
    getAdminRoleDetails()
    getBusinessDetails();
    getLocationDetails();
    getAreaDetails();
    getPlantDetails();
  }
  
  else{
  getRoleDetails();
  getBusinessDetails();
  getLocationDetails();
  getAreaDetails();
  getPlantDetails();
  }
  }, []);
const getRoleDetails = () => {
  let url = ApiURL + '/select_role';
  axios.get(url,{
    headers: {
      Authorization: `Bearer ${access_token}`,
     
    }
  }
).then((response) => {
    setRoleData(response.data.map(item => ({
      ...item,
      role: (item.role||"").charAt(0).toUpperCase()+ item.role.slice(1)
    })));
  }).catch((error) => {
    setRoleData([]);
  });
  };
  const getAdminRoleDetails = () => {
 
    let url = ApiURL +'/select_admin_role'
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
        
      }
    }
).then((response) => {
      setRoleData(response.data);
    }).catch((error) => {
      setRoleData([]);
    });
    };
  const getLocationDetails = () => {
    let url = ApiURL + '/show_location';
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
       
      }
    }
).then((response) => {
      setLocationData(response.data);
    }).catch((error) => {
      setLocationData([]);
    });
  };
  const getBusinessDetails = () => {
    let url = ApiURL + '/show_business';
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
       
      }
    }
).then((response) => {
      setBusinessData(response.data);
    }).catch((error) => {
      setBusinessData([]);
    });
    };
  const getAreaDetails = () => {
    let url = ApiURL + '/select_area';
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
        
      }
    }
).then((response) => {
      setAreaData(response.data);
    }).catch((error) => {
      setAreaData([]);
    });
    };
    const getPlantDetails = () => {
      let url = ApiURL + '/show_plant';
      axios.get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
        
        }
      }
  ).then((response) => {
        setPlantData(response.data);
      }).catch((error) => {
        setPlantData([]);
      });
      };

  
const handleClose = () => {
 onClose();
 setSelectAll(false)
 setLocation(null);
};
const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};
const validateSGID = (sgid) => {
  const sgidRegex = /^[A-Za-z][A-Za-z0-9]*(-[A-Za-z0-9]+)?$/;
  return sgidRegex.test(sgid);
};
const validateName = (name) => {
  const nameRegex = /^[A-Za-z. ]+$/; // Allows letters and spaces
  return nameRegex.test(name);
};
const validateForm = () => {
  let valid = true;
  if (!sgid || !name || !email || !role || !business||!location) {
    valid = false;
  }
  if (!validateSGID(sgid)) {
    setSgidError('Enter Valid SGID .');
    valid = false;
  } else {
    setSgidError('');
  }
  if (!validateName(name)) {
    setNameError('Enter Name .');
    valid = false;
  } else {
    setNameError('');
  }
//  if (sgid && name && sgid.charAt(0).toUpperCase() !== name.charAt(0).toUpperCase()) {
//   setNameError('First letter of SGID and Name should match.');
//   valid = false;
// }
  if (!validateEmail(email)) {
    setEmailError('Invalid email format.');
    valid = false;
  } else {
    setEmailError('');
  }
  if (!role) {
    setRole(null);
    valid = false;
  }
  if (!business) {
    setBusiness(null);
    valid = false;
  }
  
  
  setIsFormValid(valid);
  return valid;
};

const handleRoleChange = (event, newValue) => {
  setRole(newValue?newValue.id:null);
  };
  const handleBusinessChange = (event,newValue) => {
    setBusiness(newValue?newValue.id:null);
        };
 const handleLocationChange = (event, newValue) => {
          setLocation(newValue ? newValue.id : null);
        };
 
  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
        setEmail(newEmail);
        setIsEmailValid(validateEmail(newEmail));
          };
 const handleNameChange = (event) => {
    const newName = event.target.value;
    setName(newName);
          };
  const handleSgidChange = (event) => {
    const sgidValue = event.target.value;
    setSgid(sgidValue);
          };  
      
                    
  const handleSave = () => {
    setIsFormSubmitted(true)
    const isFormValid = validateForm();
   
    if(isFormValid){
      
 
      const element =  (selectedRowUserData || selectedUserCardData) ? addedUsers[0] : null

    const userId = element?element.id:null;
        const newUser = userId?{
          id:  userId ,
          sgid,
          name,
          email,
          role: role? role : '',
          business:business?business:'',
          location:location?location:'',
          plant:  selectedCreatedBy ? selectedCreatedBy: '',
          userdet:userDet.name
          }
          :{
            sgid,
            name,
            email,
            role: role? role : '',
            business:business?business:'',
            location:location?location:'',
            plant: selectedCreatedBy ? selectedCreatedBy: '',
            userdet:userDet.name
          };
          const isYearSelected = selectedUserYear !== ""; 
    if(userId){
      axios
      .post(ApiURL + '/update_users', newUser,{
        headers: {
          Authorization: `Bearer ${access_token}`,
          
        }
      }
  ) // Replace '/create_area' with your backend API endpoint to save the area data
      .then((response) => {
       
      
        let data = localStorage.getItem("user");
        let User = JSON.parse(data)
        const userset=JSON.parse(response.config.data)
        
        if(User.id===userset.id){
          User.role_id=userset.role;
          localStorage.setItem("user", JSON.stringify(User));
          if(userset.role===2){
            setUserDataSettings('2')
            onSave(newUser);
            if (!isMobileView) {
              if (isYearSelected) {
                  onHandleUserDateList(selectedUserYear);
              } else {
                  onHandleUser();
              }
          } else {
              onHandleUser();
          }
          // onHandleUser();
          onShowAlert('User updated successfully');
          navigate('/view')
          }
          else if(userset.role===1){
            setUserDataSettings('2')
            onSave(newUser);
            if (!isMobileView) {
              if (isYearSelected) {
                  onHandleUserDateList(selectedUserYear);
              } else {
                  onHandleUser();
              }
          } else {
              onHandleUser();
          }
          // onHandleUser();
          onShowAlert('User updated successfully');
          getAdminRoleDetails();
          }
          else{
            setUserDataSettings('2')
            onSave(newUser);
          // onHandleUser();
          if (!isMobileView) {
            if (isYearSelected) {
                onHandleUserDateList(selectedUserYear);
            } else {
                onHandleUser();
            }
        } else {
            onHandleUser();
        }
          onShowAlert('User updated successfully');
          }
         
        }
        else{
          if (!isMobileView) {
            if (isYearSelected) {
                onHandleUserDateList(selectedUserYear);
            } else {
                onHandleUser();
            }
        } else {
            onHandleUser();
        }
          onSave(newUser);
        // onHandleUser();
        onShowAlert('User updated successfully');
        }
        
      })
      .catch((error) => {
       
        // Handle error
        console.error('Failed to save user:', error);
      });
     
    }else{
      axios      
      .post(ApiURL + '/create_user', newUser,{
        headers: {
          Authorization: `Bearer ${access_token}`,
         
        }
      }
  ) // Replace '/save_area' with your backend API endpoint to save the area data      
      .then((response) => {          
       onSave(newUser);
       if (!isMobileView) {
        if (isYearSelected) {
            onHandleUserDateList(selectedUserYear);
        } else {
            onHandleUser();
        }
    } else {
  
        onHandleUser();
    }
           
       onShowAlert('User created successfully');
     })      
     .catch((error) => {      
       // Handle error    
       if (error.response && error.response.status === 409) {
        // SGID already exists, display error message
        alert('User already exists with the same SGID.');
      } else {
        // Other errors, log and display generic error message
        console.error('Failed to create user:', error);
        alert('Failed to create user. Please try again later.');
      }  
      //  console.error('Failed to save user:', error);      
     });
    }
   
  }
  setSelectAll(false)
  
 
};
const handleSelectAllChange = (event) => {
  if (event.target.checked) {
  
    setSelectedCreatedBy([...plantdata.map((option) => option.id)]);
    
  } else {
    
    setSelectedCreatedBy([]);
  }
  setSelectAll(event.target.checked);

};


const handleUserOptionChange = (event, option) => {
  if (!selectAll) {
  const optionId = option.id; // Assuming that each option has an 'id' property

  if (event.target.checked) {
    
    setSelectedCreatedBy((prevSelected) => [...prevSelected, optionId]);
   
  } else {
    

    setSelectedCreatedBy((prevSelected) =>
      prevSelected.filter((selectedOption) => selectedOption !== optionId)
    );
  }
 
}
};
const renderSelectedOptions = (selected) => {
  if (selected.includes("selectAll")) {
    return "Select All";
  }
  const selectedValues = selected.map((id) =>
    plantdata.find((option) => option.id === id).plantName.toUpperCase()
  );
  return selectedValues.join(", ");
};

return (
<div >
 <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">

 <DialogTitle style={{  paddingBottom: 0 }}>User Details</DialogTitle>
 <DialogContent  className={`dialog-content ${isCursorInsideDialog ? 'show-scroll' : ''}`}

     

  onMouseEnter={() => {

    setIsCursorInsideDialog(true);

  }}

  onMouseLeave={() => {

    setIsCursorInsideDialog(false);

  }}>
      {sgidError && <Typography color="error">{sgidError}</Typography>}
 <InputLabel style={modalInputLabelStyle}>
 SGID
</InputLabel>
<TextField
          name="sgid"
          value={sgid}
          onChange={handleSgidChange}
          size="small"
          className="input-nis"
          fullWidth
          style={{ marginBottom:16}}
          disabled={!!(selectedRowUserData || selectedUserCardData)}
          />
          
          {nameError && <Typography color="error">{nameError}</Typography>}
<InputLabel style={modalInputLabelStyle}>
Name
</InputLabel>
<TextField  name="name" value={name} onChange={handleNameChange}size="small"  className="input-nis" fullWidth  style={{ marginBottom:16}} />
{emailError && <Typography color="error">{emailError}</Typography>}
<InputLabel style={modalInputLabelStyle}>
Email Id
</InputLabel>
<TextField
             name="emailId"
            value={email}
            onChange={handleEmailChange}
            size="small"
            className="input-nis"
            fullWidth
            style={{ marginBottom:16}}/>
   
<InputLabel style={modalInputLabelStyle}>
Role
</InputLabel>
<Autocomplete
        className="autocomplete-nis"
        size="small"
        options={roledata} // Replace with your options array for plants
        getOptionLabel={(newValue) => newValue.role}
        onChange={handleRoleChange}
        value={roledata.find((option)=>option.id===role)||null}
        renderInput={(params) => <TextField {...params} fullWidth />}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        style={{ marginBottom:16}}
        />
        <InputLabel style={modalInputLabelStyle}>
Business
</InputLabel>
<Autocomplete
       className="autocomplete-nis"
       size="small"
        options={businessdata} // Replace with your options array for plants
        getOptionLabel={(newValue) =>newValue.business_name.toUpperCase()}
        onChange={handleBusinessChange}
        value={businessdata.find((option)=>option.id===business)||null}
        renderInput={(params) => <TextField {...params} fullWidth />}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        style={{ marginBottom:16}}
        />
         <InputLabel style={modalInputLabelStyle}>Location</InputLabel>
        <Autocomplete
          className="autocomplete-nis"
          size="small"
          options={locationdata}
          getOptionLabel={(newValue) => newValue.location.toUpperCase()}

          onChange={handleLocationChange}
          value={locationdata.find((option) => option.id === location) || null}
          renderInput={(params) => <TextField {...params} fullWidth />}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          style={{ marginBottom:16}}
        />
  <InputLabel style={modalInputLabelStyle}>Plant</InputLabel>
            <FormControl fullWidth style={{ marginBottom: 16 }}>
           
      <Select
        multiple
        value={selectedCreatedBy}
        onChange={handleUserOptionChange}
        className='select-nis'
        size="small"
        renderValue={renderSelectedOptions}
        
      >
        <MenuItem key="selectAll" value="selectAll">
          <Checkbox checked={selectAll} onChange={handleSelectAllChange} />
          <ListItemText primary="Select All" />
        </MenuItem>
        {plantdata.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <Checkbox checked={selectedCreatedBy.includes(option.id)} 
             onChange={(event) => handleUserOptionChange(event, option)}/>
            
            <ListItemText primary={option.plantName.toUpperCase()} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </DialogContent>

<DialogActions style={{ borderTop: '1px solid #f5f5f5', padding: '10px 20px' }}>
<Button variant="outlined"  onClick={handleClose} style={{  color:'#203468',marginRight: '8px',borderColor: '#203468'}}>
Cancel
</Button>
<Button variant="contained" style={{backgroundColor:'#203468'}} onClick={handleSave}>
Save
</Button>
</DialogActions>

</Dialog>
</div>
);
}
export default AddUser;