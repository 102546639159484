import  {React, useState,useEffect} from 'react';
import { Button,  RadioGroup, FormControlLabel, Radio,TextField, InputLabel,Select,MenuItem,FormControl,Typography, Autocomplete } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import configParam from '../../config';
import { plant, reportName,selectedCreatedByState, selectAllState,CustomselectedCreatedByState,CustomselectAllState,CustomreportName,Customplant,viewedReportDataState} from '../../recoil/atoms';
import { useRecoilState} from 'recoil';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import {reportboolean } from '../../recoil/atoms';


function ReportGen({open, onClose,initialOption,onGenerateReport, reportData }){
  const currentplantdata=localStorage.getItem("user_plant");
  let data = localStorage.getItem('user');
  let User = JSON.parse(data);
  const userPlantdata=currentplantdata!=null?currentplantdata.toUpperCase():((User.plant_names.find(plant => plant.id === User.plant_id[0])).name).toUpperCase();
  const plantid=localStorage.getItem("user_id")
    const [selectedOption, setSelectedOption] = useState(initialOption);
    const [selectedFromDate, setSelectedFromDate] = useState('');
    const [selectedToDate, setSelectedToDate] = useState('');
    const [selectedArea, _] = useState('');
    const [selectedYear, setSelectedYear] = useState('');              // Initialize with the current year
    const [yearOptions,setYearsOptions]=useState([]); 
    const [tabledata, setTableData] = useState([]);
    const [plantName, setPlant] = useRecoilState(plant)
    const[plantselect,setPlantSelect]=useState('')
    const [CustplantName, setCustomPlant] = useRecoilState(Customplant);
    const[Customplantselect,setCustomPlantSelect]=useState('')
    const [,setReportName]=useRecoilState(reportName)
    const[reportselect,setReportSelect]=useState('')
    const [,setCustomReportName]=useRecoilState(CustomreportName)
    const[Custreportselect,setCustomReportSelect]=useState('')
    const [, setErrorMessageDate] = useState(null);
  const [selectedCreatedBy, setSelectedCreatedBy] = useRecoilState(selectedCreatedByState); // To store the selected options
const [selectAll, setSelectAll] = useRecoilState(selectAllState); // To handle "Select All" checkbox
const [createdByOptions, setCreatedByOptions] = useState([]);
const [selectedCreatedByCustom, setSelectedCreatedByCustom] = useRecoilState(CustomselectedCreatedByState); // To store the selected options
const [selectAllCustom, setSelectAllCustom] = useRecoilState(CustomselectAllState); // To handle "Select All" checkbox
const [createdByOptionsCustom, setCreatedByOptionsCustom] = useState([]);
const[,setnextReport]=useRecoilState(reportboolean)
const [viewedReportData,setViewedReportData] = useRecoilState(viewedReportDataState);
const isEditMode = Boolean(viewedReportData.id);
const [validationError, setValidationError] = useState('');
const [access_token,setAccessToken]=useState(localStorage.getItem("accesstoken"))

const moment = require('moment');
    const ApiURL = configParam.API_URL;
 
    useEffect(() => {
      // Function to check for accessToken changes
      const handleAccessTokenChange = () => {
        const storedAccessToken = localStorage.getItem("accesstoken");
        if (storedAccessToken !== access_token) {
          setAccessToken(storedAccessToken);
        }
      };
    
      // Listen for storage events (for cross-tab changes)
      window.addEventListener("storage", handleAccessTokenChange);
    
      // Check for changes every second (for same-tab changes)
      const interval = setInterval(handleAccessTokenChange, 1000);
    
      // Cleanup listeners and interval on component unmount
      return () => {
        window.removeEventListener("storage", handleAccessTokenChange);
        clearInterval(interval);
      };
    }, [access_token]); // Dependency on accessToken
 
    useEffect(() => {
      if (initialOption === 'annualrep' && reportData && reportData.year) {
        // For annual report, set the selected options
        setSelectedOption(initialOption);
        setSelectedYear(reportData.year);
        setPlantSelect(reportData.plant_id || '');
        setReportSelect(reportData.reportName || '');
        setReportName(reportData.reportName || '')
        setSelectedCreatedBy(reportData.created_by_filter.map(Number) || []);
      } else if (initialOption === 'custrep' && reportData && reportData.fromdate && reportData.todate) {
        // For custom report, set the selected options
      
        setSelectedOption(initialOption);
        // const fromDate = reportData.formatted_from_date ? new Date(reportData.formatted_from_date) : null;
        // fromDate.setDate(fromDate.getDate() + 1);
        setSelectedFromDate(reportData.fromdate || '');

        // Get date + 1 for toDate
        // const toDate = reportData.todate ? new Date(reportData.todate) : null;
        // toDate.setDate(toDate.getDate() + 1);
        setSelectedToDate(reportData.todate || '');
  
        setCustomPlantSelect(reportData.plant_id || '');
        setCustomReportSelect(reportData.reportName || '');
        setCustomReportName( reportData.reportName || '')
        setSelectedCreatedByCustom(reportData.created_by_filter.map(Number) || []);
      }
    }, [initialOption, reportData]);
    useEffect(() => {
      
      gettrialreportYears();
    }, []);

    useEffect(() => {
      getPlantDetails();
      
    }, []);
  
    useEffect(() => {
      getCreatedByOptions();
    }, []);
    useEffect(() => {
      getCreatedByCustomOptions();
    }, []);
    const modalInputLabelStyle = {
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
      color: '#000',
    };
    const modalInputLabelSelectedStyle = {
      fontSize: 16,
      fontWeight: 600,
      color: '#000',
    };
    const gettrialreportYears = () => {
      let url = ApiURL + '/show_trial_details_year';
      axios.get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
         
        }
      }
).then((response) => {
        setYearsOptions(response.data);
      }).catch((error) => {
        setYearsOptions([]);
      });
    };
    const getCreatedByOptions = () => {
      let url = ApiURL + '/show_reports_user';
      axios
        .get(url,{
          headers: {
            Authorization: `Bearer ${access_token}`,
           
          }
        }
  )
        .then((response) => {
       
           setCreatedByOptions(response.data);
        })
        .catch((error) => {
          console.error('API error:', error);
          setCreatedByOptions([]);
        });
    };
    const getCreatedByCustomOptions = () => {
      let url = ApiURL + '/show_reports_user';
      axios
        .get(url,{
          headers: {
            Authorization: `Bearer ${access_token}`,
            
          }
        }
  )
        .then((response) => {
       
          setCreatedByOptionsCustom(response.data);
        })
        .catch((error) => {
          console.error('API error:', error);
          setCreatedByOptionsCustom([]);
        });
    };
    const getPlantDetails = () => {
      let url = ApiURL + '/show_plant';
      axios.get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
         
        }
      }
).then((response) => {
        setTableData(response.data);
      }).catch((error) => {
        setTableData([]);
      });
    };
    const navigate = useNavigate();
    useEffect(() => {
      setSelectedOption(initialOption);
    }, [initialOption]);
    
  


    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
    
    };
    const handleYearChange = (event) => {
      if (!isEditMode) {
      setSelectedYear(event.target.value);
      }
      else {
        // In edit mode, show an error message
        setValidationError("You cannot change Year.");
      }
    };
   
    const handleReportNameChange = (event) => {
      
      setReportName(event.target.value);
     
      setReportSelect(event.target.value)
  
    };
    const handleCustomReportNameChange = (event) => {
    
      setCustomReportName(event.target.value);
      setCustomReportSelect(event.target.value)
    };
    const handlePlantChange = (event, newValue) => {
      if (!isEditMode) {
     
      setPlant(newValue ? newValue.id : null);
      setPlantSelect(newValue ? newValue.id : null)
      }else {
        // In edit mode, show an error message
        setValidationError("You cannot change Plant.");
      }
    };
    const handleCustomPlantChange = (event, newValue) => {
      if (!isEditMode) {
      setCustomPlant(newValue ? newValue.id : null);
      setCustomPlantSelect(newValue ? newValue.id : null)
      }else{
        setValidationError("You cannot change Plant.");
      }
    };
    const handleSave = () => {
      let reportData;
      if (selectedOption === 'annualrep') {
         navigate('/AnnualReport', { state: { selectedYear } });
         setnextReport(true);
         reportData = {
            reportType: selectedOption,
            from: selectedFromDate,
            to: selectedToDate,
            copy: selectedArea,
            reportName: reportselect,
            created_by_filter: selectedCreatedBy,
         };
      } else if (selectedOption === 'custrep') {
         navigate('/customreport', { state: { selectedFromDate, selectedToDate } });
         setnextReport(true);
         reportData = {
            reportType: selectedOption,
            from: selectedFromDate,
            to: selectedToDate,
            copy: selectedArea,
            reportName: Custreportselect,
            created_by_filter: selectedCreatedByCustom,
         };
      }
      
      onGenerateReport(reportData);
      onClose();
      setPlantSelect('');
      setReportSelect('');
      setCustomPlantSelect('');
      setCustomReportSelect('');
      setSelectedYear('');
      setSelectedFromDate('');
      setSelectedToDate('');
   };
   
   
    const handleCancel = () => {
      setPlantSelect('');
      setReportSelect('')
      setCustomPlantSelect('')
      setCustomReportSelect('')
      setSelectedYear('')
      setSelectedFromDate('');
      setSelectedToDate('')
      setSelectedCreatedBy([]); // Clear the selectedCreatedBy state
     setSelectAll(false);
     setSelectedCreatedByCustom([]);
     setSelectAllCustom(false)
     setViewedReportData({})
      onClose();
      
    };
    const handleSelectAllChange = (event) => {
      if (event.target.checked) {
      
        setSelectedCreatedBy([...createdByOptions.map((option) => option.created_by)]);
        
      } else {
        
        setSelectedCreatedBy([]);
      }
      setSelectAll(event.target.checked);
  
    };
    
    const handleUserOptionChange = (event, option) => {
   
      if (!selectAll) {
      const optionId = option.created_by; // Assuming that each option has an 'id' property
    
      if (event.target.checked) {
        
        setSelectedCreatedBy((prevSelected) => [...prevSelected, optionId]);
       
      } else {
        

        setSelectedCreatedBy((prevSelected) =>
          prevSelected.filter((selectedOption) => selectedOption !== optionId)
        );
      }
     
    }
    };

    
    const renderSelectedOptions = (selected) => {
      if (!createdByOptions || createdByOptions.length === 0) {
        return "Select All";
      }
    
      const selectedValues = (selected || []).map((id) => {
        const selectedOption = createdByOptions.find((option) => option.created_by === id);
        return selectedOption ? selectedOption.name : '';
      });
    
      return selectedValues.join(", ");
    };
    
    const handleSelectAllCustomChange = (event) => {
      if (event.target.checked) {
      
        setSelectedCreatedByCustom([...createdByOptionsCustom.map((option) => option.created_by)]);
        
      } else {
        
        setSelectedCreatedByCustom([]);
      }
      setSelectAllCustom(event.target.checked);
  
    };
    
    
    const handleUserOptionCustomChange = (event, option) => {
      if (!selectAll) {
      const optionId = option.created_by; // Assuming that each option has an 'id' property
    
      if (event.target.checked) {
        
        setSelectedCreatedByCustom((prevSelected) => [...prevSelected, optionId]);
       
      } else {
        

        setSelectedCreatedByCustom((prevSelected) =>
          prevSelected.filter((selectedOption) => selectedOption !== optionId)
        );
      }
     
    }
    };
    const renderSelectedOptionsCustom = (selected) => {
      if (!createdByOptionsCustom || createdByOptionsCustom.length === 0) {
        return "Select All";
      }
    
      const selectedValues = (selected || []).map((id) => {
        const selectedOption = createdByOptionsCustom.find((option) => option.created_by === id);
        return selectedOption ? selectedOption.name : '';
      });
    
      return selectedValues.join(", ");
    
    };
const handleFromDateChange=(event)=>{
  const dateTimeString = event.target.value; // Use default value if date is invalid
  const formattedDate = moment.utc(dateTimeString).format("YYYY-MM-DD");
  const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

  if (formattedDate > today) {
    setErrorMessageDate("You cannot select a future date.");
  } else {
    setErrorMessageDate('');
    setSelectedFromDate(formattedDate);
 
   
  }
}

const textFieldStyle = {
  fontSize: 16,
  fontWeight: 600,
  color: '#000',
  marginBottom: 16, 
  backgroundColor: '#ececec', // Assuming you want consistent marginBottom as per your original styling
};
const handleToDateChange=(event)=>{
  const dateTimeString = event.target.value; // Use default value if date is invalid
  const formattedDate = moment.utc(dateTimeString).format("YYYY-MM-DD");
  const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

  if (formattedDate > today) {
    setErrorMessageDate("You cannot select a future date.");
  } else {
    setErrorMessageDate('');
    setSelectedToDate(formattedDate);
  }
}

// const displayValue = userPlantdata === '{user_id}' ? user_id : userPlantdata;

    const today = new Date().toISOString().split('T')[0];

    return(
      <div >
      <Dialog open={open} fullWidth   maxWidth="xs">
   
       <DialogTitle style={{  paddingBottom: 0 ,fontSize:"18px",fontWeight:600}}>Generate Report</DialogTitle>

        <DialogContent style={{ overflowY: 'auto', maxHeight: '60vh' }}>
        <hr style={{ margin: '3px 0', borderColor: '#0000001A'}} />
        <div   style={{ display: 'flex', alignItems: 'center'}}>
          <RadioGroup value={selectedOption} onChange={handleOptionChange} row style={{ flex: 1, gap: '15px' }}>
            <FormControlLabel value="annualrep" control={<Radio />} label={
                  <Typography
                    style={selectedOption === 'annualrep' ? modalInputLabelSelectedStyle : modalInputLabelStyle}
                  >
                    Annual Report
                  </Typography>
                } />
            <FormControlLabel value="custrep" control={<Radio />}  label={
                  <Typography
                    style={selectedOption === 'custrep' ? modalInputLabelSelectedStyle : modalInputLabelStyle}
                  >
                    Custom Report
                  </Typography>
                } />
          </RadioGroup>
        </div>

        {selectedOption === 'annualrep' ? (
          <div>
            <InputLabel style={modalInputLabelStyle}>Year</InputLabel>
            <FormControl fullWidth style={{ marginBottom:16}}>
            <Select
          size="small"
          className='select-nis'
          value={selectedYear}
          onChange={handleYearChange}
          disabled={isEditMode}
        >
          {yearOptions.map((year, index) => (
      <MenuItem key={index} value={year.year}>
        {year.year}
      </MenuItem>
    ))}
        </Select>
            
            </FormControl>
            {validationError && (
            <Typography variant="caption" color="error">
              {validationError}
            </Typography>
          )}
          <InputLabel style={modalInputLabelStyle}>Plant</InputLabel>
<TextField
  fullWidth
  variant="outlined"
  value={userPlantdata}
  disabled
  style={textFieldStyle}
/>
        
            {/* <Autocomplete
          className="autocomplete-nis"
          size="small"
          options={tabledata}
          getOptionLabel={(newValue) => newValue.plantName.toUpperCase()}
          onChange={handlePlantChange}
          value={tabledata.find((option) => option.id === plantselect) || null}
          renderInput={(params) => <TextField {...params} fullWidth />}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          style={{ marginBottom:16}}
          disabled={isEditMode}
        /> */}
            {validationError && (
            <Typography variant="caption" color="error">
              {validationError}
            </Typography>
          )}
            <InputLabel style={modalInputLabelStyle}>ReportName</InputLabel>
            <FormControl fullWidth style={{ marginBottom:16}}>
            <TextField
              fullWidth
              
              value={reportselect}
              onChange={handleReportNameChange}
              size="small"  
              className="input-nis" 
              
            />
            </FormControl>
            <InputLabel style={modalInputLabelStyle}>Created By</InputLabel>
            <FormControl fullWidth style={{ marginBottom: 16 }}>
           
      <Select
        multiple
        value={selectedCreatedBy}
        onChange={handleUserOptionChange}
        className='select-nis'
        size="small"
        renderValue={renderSelectedOptions}
        
      >
        <MenuItem key="selectAll" value="selectAll">
          <Checkbox checked={selectAll} onChange={handleSelectAllChange} />
          <ListItemText primary="Select All" />
        </MenuItem>
        {createdByOptions.map((option) => (
          <MenuItem key={option.created_by} value={option.created_by}>
            <Checkbox checked={selectedCreatedBy.includes(option.created_by)} 
             onChange={(event) => handleUserOptionChange(event, option)}/>
            
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
           
          </div>
        ) : (
          <div>
            <div style={{ display: 'flex',gap:"25px"}}>
             <div>
             <InputLabel style={modalInputLabelStyle}>From Date</InputLabel>
             <FormControl fullWidth style={{ marginBottom:16}}>
             <TextField
              fullWidth
              id="fromDate"
              type="date"
              value={selectedFromDate}
              onChange={handleFromDateChange}
              size="small"  
              className="input-nis" 
              inputProps={{ max: today }}
             />
             </FormControl>
             </div>
             <div>
                <InputLabel style={modalInputLabelStyle}>To Date</InputLabel>
                <FormControl fullWidth style={{ marginBottom:16}}>
                <TextField
                  fullWidth
                  id="toDate"
                  type="date"
                  value={selectedToDate}
                  onChange={handleToDateChange}
                  size="small"  
                  className="input-nis" 
                  inputProps={{ max: today }}
                />
                </FormControl>
              </div>
            </div>
            <InputLabel style={modalInputLabelStyle}>Report Name</InputLabel>
            <FormControl fullWidth style={{ marginBottom:16}}>
            <TextField
              fullWidth
              
              value={Custreportselect}
              onChange={handleCustomReportNameChange}
              size="small"  
              className="input-nis" 
              
            />
            </FormControl>
            <InputLabel style={modalInputLabelStyle}>Plant</InputLabel>
            <TextField
        fullWidth
        variant="outlined"
        value={userPlantdata}
        disabled
        style={textFieldStyle}
      />
            
            {/* <Autocomplete
          className="autocomplete-nis"
          size="small"
          options={tabledata}
          getOptionLabel={(newValue) => newValue.plantName.toUpperCase()}
          onChange={handleCustomPlantChange}
          value={tabledata.find((option) => option.id === Customplantselect) || null}
          renderInput={(params) => <TextField {...params} fullWidth />}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          style={{ marginBottom:16}}
          disabled={isEditMode}
        /> */}
            <InputLabel style={modalInputLabelStyle}>Created By</InputLabel>
            <FormControl fullWidth style={{ marginBottom: 16 }}>
           
      <Select
        multiple
        value={selectedCreatedByCustom}
        onChange={handleUserOptionCustomChange}
        className='select-nis'
        size="small"
        renderValue={renderSelectedOptionsCustom}
        
      >
        <MenuItem key="selectAll" value="selectAll">
          <Checkbox checked={selectAllCustom} onChange={handleSelectAllCustomChange} />
          <ListItemText primary="Select All" />
        </MenuItem>
        {createdByOptionsCustom.map((option) => (
          <MenuItem key={option.created_by} value={option.created_by}>
            <Checkbox checked={selectedCreatedByCustom.includes(option.created_by)} 
             onChange={(event) => handleUserOptionCustomChange(event, option)}/>
            
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
         </Select>
        </FormControl>
             </div>
     )}
       </DialogContent> 
       
        <DialogActions style={{ borderTop: '1px solid #f5f5f5', padding: '10px 20px' }}>
        <Button variant="outlined" style={{ marginRight: '8px', color: '#203468', borderColor: '#002060' }} onClick={handleCancel}>
  Cancel
</Button>
<Button variant="contained" style={{ backgroundColor: '#002060' }} onClick={handleSave}>
  Next
</Button>
         
          </DialogActions>
       
       
    </Dialog>
    </div>
    )
}
export default ReportGen;